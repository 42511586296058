import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerProveedores() {
    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.get(url);
  }, 

  importarSuppliersProductosPrices(data, file) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "costs/suppliers-products/import/CSV" + Session.getToken();
    
    return instance.post(url,params,{headers});
  },    

  obtenerProveedoresOnlyImport(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/filter/suppliers" + Session.getToken();
    return instance.post(url,params);    
  },
  obtenerProveedoresForTree(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/filter/treeSuppliers" + Session.getToken();
    return instance.post(url,params);    
  },
  obtenerProductsByFilter(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/filter/treeQuery" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);        
  },
  obtenerLista() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },  
  saveProduct(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "costs/suppliers-products/convert" + Session.getToken();
    
    return instance.post(url,params);    
  },
  mostrarProductosByLista(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products/filter/showProducts" + Session.getToken();
    return instance.post(url,params);
  },
  marcarDefault(data) {
    var params = data
    var url = ConfigAPI.baseURL + "costs/suppliers-products/setDefault" + Session.getToken();
    return instance.post(url,params); 
  },
  updateProducto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products/update" + Session.getToken();
    return instance.post(url,params);
  },      
  deleteProducto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products/delete" + Session.getToken();
    return instance.post(url,params);
  },        
  compareProductos(data) {
    var params = data
    var url = ConfigAPI.baseURL + "costs/filter/compare" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerImportListCost(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products-import" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  obtenerImportDetailListCost(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products-import/show" + Session.getToken();
    return instance.post(url,params);
  },

  updateProductoLote(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products/updateLot" + Session.getToken();
    return instance.post(url,params);
  },
  updateProductoDescuentoLote(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "costs/suppliers-products/updateDiscountLot" + Session.getToken();
    return instance.post(url,params);
  }  
}

export default servicesAPI;
